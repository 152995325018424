<template>
  <div
    class="exhi-app"
    :style="`${mobile && !shrinkLeftMenu ? 'opacity: 0' : 'opacity: 1'}`"
  >
    <div class="exhi-container">
      <resize-observer @notify="handleResize" />
      <div class="cardList">
        <button
          class="cardList__btn btn btn--left"
          ref="btnLeft"
          v-show="!visible && !mobile"
          @click="swapCards('left')"
        >
          <div class="icon">
            <svg>
              <use xlink:href="#arrow-left"></use>
            </svg>
          </div>
        </button>

        <div class="cards__wrapper" ref="cardsWrapper">
          <div class="card leftmost--card" @click="handleImgPreview(0, $event)">
            <div class="card__image">
              <img :src="get(renderedImageUrls[0], 'azure_blob')" alt="" />
            </div>
          </div>

          <div
            class="card leftsecond--card"
            @click="handleImgPreview(1, $event)"
          >
            <div class="card__image">
              <img :src="get(renderedImageUrls[1], 'azure_blob')" alt="" />
            </div>
          </div>

          <div class="card previous--card" @click="handleImgPreview(2, $event)">
            <div class="card__image">
              <img :src="get(renderedImageUrls[2], 'azure_blob')" alt="" />
            </div>
          </div>

          <div class="card current--card" @click="handleImgPreview(3, $event)">
            <div class="card__image">
              <img :src="get(renderedImageUrls[3], 'azure_blob')" alt="" />
            </div>
          </div>

          <div class="card next--card" @click="handleImgPreview(4, $event)">
            <div class="card__image">
              <img :src="get(renderedImageUrls[4], 'azure_blob')" alt="" />
            </div>
          </div>

          <div
            class="card rightsecond--card"
            @click="handleImgPreview(5, $event)"
          >
            <div class="card__image">
              <img :src="get(renderedImageUrls[5], 'azure_blob')" alt="" />
            </div>
          </div>

          <div
            class="card rightmost--card"
            @click="handleImgPreview(6, $event)"
          >
            <div class="card__image">
              <img :src="get(renderedImageUrls[6], 'azure_blob')" alt="" />
            </div>
          </div>
        </div>

        <button
          class="cardList__btn btn btn--right"
          ref="btnRight"
          v-show="!visible && !mobile"
          @click="swapCards('right')"
        >
          <div class="icon">
            <svg>
              <use xlink:href="#arrow-right"></use>
            </svg>
          </div>
        </button>
      </div>

      <div class="infoList">
        <div class="info__wrapper" ref="infoWrapper">
          <div class="info leftmost--info">
            <h1 class="text name">
              {{ get(renderedImageUrls[0], "Name_En") }}
            </h1>
            <h4 class="text year">{{ get(renderedImageUrls[0], "Year") }}</h4>
            <p class="text painter">
              {{ get(renderedImageUrls[0], "Painter_En") }}
            </p>
            <p class="text museum">
              {{ get(renderedImageUrls[0], "Museum_En") }}
            </p>
          </div>

          <div class="info leftsecond--info">
            <h1 class="text name">
              {{ get(renderedImageUrls[1], "Name_En") }}
            </h1>
            <h4 class="text year">{{ get(renderedImageUrls[1], "Year") }}</h4>
            <p class="text painter">
              {{ get(renderedImageUrls[1], "Painter_En") }}
            </p>
            <p class="text museum">
              {{ get(renderedImageUrls[1], "Museum_En") }}
            </p>
          </div>

          <div class="info previous--info">
            <h1 class="text name">
              {{ get(renderedImageUrls[2], "Name_En") }}
            </h1>
            <h4 class="text year">{{ get(renderedImageUrls[2], "Year") }}</h4>
            <p class="text painter">
              {{ get(renderedImageUrls[2], "Painter_En") }}
            </p>
            <p class="text museum">
              {{ get(renderedImageUrls[2], "Museum_En") }}
            </p>
          </div>

          <div class="info current--info">
            <h1 class="text name">
              {{ get(renderedImageUrls[3], "Name_En") }}
            </h1>
            <h4 class="text year">{{ get(renderedImageUrls[3], "Year") }}</h4>
            <p class="text painter">
              {{ get(renderedImageUrls[3], "Painter_En") }}
            </p>
            <p class="text museum">
              {{ get(renderedImageUrls[3], "Museum_En") }}
            </p>
          </div>

          <div class="info next--info">
            <h1 class="text name">
              {{ get(renderedImageUrls[4], "Name_En") }}
            </h1>
            <h4 class="text year">{{ get(renderedImageUrls[4], "Year") }}</h4>
            <p class="text painter">
              {{ get(renderedImageUrls[4], "Painter_En") }}
            </p>
            <p class="text museum">
              {{ get(renderedImageUrls[4], "Museum_En") }}
            </p>
          </div>

          <div class="info rightsecond--info">
            <h1 class="text name">
              {{ get(renderedImageUrls[5], "Name_En") }}
            </h1>
            <h4 class="text year">{{ get(renderedImageUrls[5], "Year") }}</h4>
            <p class="text painter">
              {{ get(renderedImageUrls[5], "Painter_En") }}
            </p>
            <p class="text museum">
              {{ get(renderedImageUrls[5], "Museum_En") }}
            </p>
          </div>

          <div class="info rightmost--info">
            <h1 class="text name">
              {{ get(renderedImageUrls[6], "Name_En") }}
            </h1>
            <h4 class="text year">{{ get(renderedImageUrls[6], "Year") }}</h4>
            <p class="text painter">
              {{ get(renderedImageUrls[6], "Painter_En") }}
            </p>
            <p class="text museum">
              {{ get(renderedImageUrls[6], "Museum_En") }}
            </p>
          </div>
        </div>
      </div>

      <div class="app__bg" ref="appBg">
        <div class="app__bg__image leftmost--image">
          <img :src="get(renderedImageUrls[0], 'azure_blob')" alt="" />
        </div>
        <div class="app__bg__image leftsecond--image">
          <img :src="get(renderedImageUrls[1], 'azure_blob')" alt="" />
        </div>
        <div class="app__bg__image previous--image">
          <img :src="get(renderedImageUrls[2], 'azure_blob')" alt="" />
        </div>
        <div class="app__bg__image current--image">
          <img :src="get(renderedImageUrls[3], 'azure_blob')" alt="" />
        </div>
        <div class="app__bg__image next--image">
          <img :src="get(renderedImageUrls[4], 'azure_blob')" alt="" />
        </div>
        <div class="app__bg__image rightsecond--image">
          <img :src="get(renderedImageUrls[5], 'azure_blob')" alt="" />
        </div>
        <div class="app__bg__image rightmost--image">
          <img :src="get(renderedImageUrls[6], 'azure_blob')" alt="" />
        </div>
      </div>
    </div>

    <div class="loading__wrapper">
      <div class="loader--text">Loading...</div>
      <div class="loader">
        <span></span>
      </div>
    </div>

    <svg class="icons" style="display: none">
      <symbol
        id="arrow-left"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 512 512"
      >
        <polyline
          points="328 112 184 256 328 400"
          style="
            fill: none;
            stroke: #fff;
            stroke-linecap: round;
            stroke-linejoin: round;
            stroke-width: 48px;
          "
        />
      </symbol>
      <symbol
        id="arrow-right"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 512 512"
      >
        <polyline
          points="184 112 328 256 184 400"
          style="
            fill: none;
            stroke: #fff;
            stroke-linecap: round;
            stroke-linejoin: round;
            stroke-width: 48px;
          "
        />
      </symbol>
    </svg>
    <div style="display: none">
      <img
        fetchPriority="high"
        :src="get(last(leftHiddenImagesStack[genre]), 'azure_blob')"
        alt=""
      />
      <img
        fetchPriority="high"
        :src="get(first(rightHiddenImagesStack[genre]), 'azure_blob')"
        alt=""
      />
    </div>
    <image-preview
      :image-urls="Array(1).fill(this.currentImageUrl)"
      :visible.sync="visible"
      :isMobile="mobile"
      :isIpad="ipad"
      :metaInfo="metaInfo"
      :liked="likeit"
      @like="handleLikeAction"
    ></image-preview>
  </div>
</template>

<script>
import gsap from "gsap";
import { get, set, last, first, throttle } from "lodash";
import imagesLoaded from "imagesloaded";
import { mapActions, mapGetters, mapMutations } from "vuex";

export default {
  name: "ExhibitionContainer",

  metaInfo() {
    const genre = this.genre;
    const currentImageUrl = this.currentImageUrl;
    return {
      // title will be injected into parent titleTemplate
      title: `${genre}`,
      meta: [
        {
          property: "og:title",
          content: `${genre}`,
          template: (chunk) => `${chunk} | Boost Art Net`,
        },
        {
          property: "og:image",
          content: `${currentImageUrl}`,
        },
        {
          property: "twitter:title",
          content: `${genre}`,
          template: (chunk) => `${chunk} | Boost Art Net`,
        },
        {
          property: "twitter:image",
          content: `${currentImageUrl}`,
        },
      ],
    };
  },

  data() {
    return {
      likeit: false, // like this image or not
      visible: false, // whether image previewer visible
      startPosition: 0,
      mobile: false,
      ipad: false,
      currentImageUrl:
        "https://westartbucket101.blob.core.windows.net/2021/Modigliani053.png",
      documentEvtFunc: null,
    };
  },

  computed: {
    ...mapGetters({
      genre: "getGenre",
      imagesData: "getImagesData",
      leftHiddenImagesStack: "getLeftHiddenImagesStack",
      rightHiddenImagesStack: "getRightHiddenImagesStack",
      shrinkLeftMenu: "getShrinkLeftMenu",
      collectionResult: "getCollectionResult",
      hardwareBackActive: "getHardwareBackActive",
    }),

    paintingIndex() {
      return this.currentImageUrl?.split("/").slice(-1)[0].split(".")[0];
    },

    renderedImageUrls() {
      return this.imagesData[this.genre].map((el) => {
        return Object.assign(
          {},
          {
            azure_blob: el.azure_blob,
            Painter_En: el.Painter_En,
            Year: el.Year,
            Name_En: el.Name_En,
            Museum_En: el.Museum_En,
          }
        );
      });
    },

    cardsContainerEl() {
      return this.$refs.cardsWrapper;
    },

    cardInfosContainerEl() {
      return this.$refs.infoWrapper;
    },

    appBgContainerEl() {
      return this.$refs.appBg;
    },

    firstPaintedDetector() {
      if (this.$refs.cardsWrapper) {
        return (
          this.$refs.cardsWrapper.lastChild.lastChild.lastChild
            .getAttribute("src")
            .slice(0, 5) === "https"
        );
      }
      return undefined;
    },

    metaInfo() {
      const paintingIndex = this.currentImageUrl
        .split("/")
        .slice(-1)[0]
        .split(".")[0]; // this.paintingIndex may not get value
      const url = `https://www.boost-art.net/painting/${paintingIndex}?share`;
      const title = this.$t("00003");
      const description =
        "Boost Art Net APP presents. Install: Admire thousands of captivating masterpieces right in your pocket; Museum: The exact destination of masterpiece to help plan your art travel well; Wander: Scroll and browse the chronicle of Western fine art along the way; Search: Handy search entry to reach out to your favorite painters and paintings.";
      const media = this.currentImageUrl;
      return {
        url,
        title,
        description,
        media,
        hashtags: "art",
      };
    },
  },

  watch: {
    visible(newValue) {
      this.updateModalVisible(newValue);
      if (newValue) {
        this.terminateKeyboardEvent();
      } else {
        !this.documentEvtFunc && this.registerKeyboardEvent();
      }
    },
    shrinkLeftMenu(newValue) {
      if (newValue === false) {
        this.terminateKeyboardEvent();
      } else {
        !this.documentEvtFunc && this.registerKeyboardEvent();
      }
    },
    hardwareBackActive: {
      immediate: true,
      handler(newValue) {
        if (newValue) {
          this.visible = false;
          this.updateModalVisible(this.visible);
        }
      },
    },
  },

  methods: {
    ...mapActions([
      "initializeImagesData",
      "leftUnshiftImagesData",
      "rightPushImagesData",
      "leftHiddenImagesStackPop",
      "rightHiddenImagesStackShift",
      "fetchCollectionResult",
      "addLikeToCollection",
      "removeLikeFromCollection",
      "updateModalVisible",
    ]),
    ...mapMutations(["LEFT_PUSH_IMAGES_DATA", "RIGHT_UNSHIFT_IMAGES_DATA"]),

    get,
    last,
    first, // used in tempalate

    handleImgPreview(index, $event) {
      // prevent the image preview action when the left menu is shown and mobile
      if (this.mobile && !this.shrinkLeftMenu) return;
      const boxClass = $event.target.classList.value;
      if (boxClass.search("current--card") > -1) {
        this.currentImageUrl = $event.target
          .querySelector(".card__image img")
          .getAttribute("src");
        this.$nextTick(async () => {
          this.visible = true;
          this.updateModalVisible(this.visible);
          this.startPosition = index;

          // Get like information
          // const token = this.$cookie.get("boost-art-net-token") || "";
          // await this.fetchCollectionResult(token);
          await this.fetchCollectionResult();
          const that = this;
          this.$nextTick(() => {
            if (
              this.collectionResult.some(
                (el) => el?.Painting_Index === this.paintingIndex
              )
            ) {
              that.likeit = true;
            } else {
              that.likeit = false;
            }
          });
        });
      }
    },

    handleLikeAction(yes) {
      // const token = this.$cookie.get("boost-art-net-token") || "";
      if (yes) {
        this.likeit = true;
        // this.addLikeToCollection({ token: token, index: this.paintingIndex });
        this.addLikeToCollection({ index: this.paintingIndex });
      } else {
        this.likeit = false;
        // this.removeLikeFromCollection({
        //   token: token,
        //   index: this.paintingIndex,
        // });
        this.removeLikeFromCollection({
          index: this.paintingIndex,
        });
      }
    },

    handleSpaceTriggeredImgPreview() {
      const event = new MouseEvent("click", {
        view: window,
        bubbles: true,
        cancelable: true,
      });
      const currentCardEl =
        this.cardsContainerEl.querySelector(".current--card");
      currentCardEl.dispatchEvent(event);
    },

    handleResize({ width }) {
      this.mobile = width <= 450;
      this.ipad = Boolean(
        !!navigator.userAgent.match(/(iPad).*OS\s([\d_]+)/) || // browser devtools ipad
          (/Macintosh/i.test(navigator.userAgent) && // real ipad devices
            navigator.maxTouchPoints &&
            navigator.maxTouchPoints > 1)
      );
      const cards = Array.from(this.cardsContainerEl.children);
      if (
        (this.mobile || this.ipad) &&
        cards.every(
          (card) => card.getAttribute("touchstartListener") !== "true"
        )
      ) {
        // enable touch to left and touch to right
        this.cardsContainerEl.children.forEach((card) => {
          card.setAttribute("touchstartListener", "true");
          card.addEventListener("touchstart", (e) => {
            this.touchStart(e);
          });
        });
      }
    },

    registerKeyboardEvent() {
      this.documentEvtFunc = (event) => {
        const key = event.key.toLowerCase();

        if (key === "arrowleft") {
          console.log("arrowleft");
          this.swapCards("left");
        } else if (key === "arrowright") {
          console.log("arrowright");
          this.swapCards("right");
        } else if (key === "control") {
          this.handleSpaceTriggeredImgPreview();
        }
      };
      document.addEventListener("keydown", this.documentEvtFunc);
    },

    terminateKeyboardEvent() {
      document.removeEventListener("keydown", this.documentEvtFunc);
      this.documentEvtFunc = null;
    },

    touchStart(touchEvent) {
      if (touchEvent.changedTouches.length !== 1) {
        // We only care if one finger is used
        return;
      }
      const posXStart = touchEvent.changedTouches[0].clientX;
      addEventListener(
        "touchend",
        (touchEvent) => this.touchEnd(touchEvent, posXStart),
        { once: true }
      );
    },

    touchEnd(touchEvent, posXStart) {
      if (touchEvent.changedTouches.length !== 1) {
        // We only care if one finger is used
        return;
      }
      const posXEnd = touchEvent.changedTouches[0].clientX;
      if (posXStart < posXEnd) {
        console.log("swipe left");
        this.swapCards("left"); // swipe left to right
      } else if (posXStart > posXEnd) {
        console.log("swipe right");
        this.swapCards("right"); // swipe right to left
      }
    },

    initCardEvents() {
      const currentCardEl =
        this.cardsContainerEl.querySelector(".current--card");
      currentCardEl.addEventListener("pointermove", this.updateCard);
      currentCardEl.addEventListener("pointerout", (e) => {
        this.resetCardTransforms(e);
      });
    },

    resetCardTransforms(e) {
      const card = e.currentTarget;
      const currentInfoEl =
        this.cardInfosContainerEl.querySelector(".current--info");
      gsap.set(card, {
        "--current-card-rotation-offset": 0,
      });
      gsap.set(currentInfoEl, {
        rotateY: 0,
      });
    },

    updateCard(e) {
      const card = e.currentTarget;
      const box = card.getBoundingClientRect();
      const centerPosition = {
        x: box.left + box.width / 2,
        y: box.top + box.height / 2,
      };
      let angle = Math.atan2(e.pageX - centerPosition.x, 0) * (35 / Math.PI);
      gsap.set(card, {
        "--current-card-rotation-offset": `${angle}deg`,
      });
      const currentInfoEl =
        this.cardInfosContainerEl.querySelector(".current--info");
      gsap.set(currentInfoEl, {
        rotateY: `${angle}deg`,
      });
    },

    removeCardEvents(card) {
      card.removeEventListener("pointermove", this.updateCard);
    },

    swapCardsClass(direction, cardsArray, bgsArray) {
      const [
        currentCardEl,
        previousCardEl,
        nextCardEl,
        leftsecondCardEl,
        leftmostCardEl,
        rightsecondCardEl,
        rightmostCardEl,
      ] = cardsArray;
      const [
        currentBgImageEl,
        previousBgImageEl,
        nextBgImageEl,
        leftsecondBgImageEl,
        leftmostBgImageEl,
        rightsecondBgImageEl,
        rightmostBgImageEl,
      ] = bgsArray;
      currentCardEl.classList.remove("current--card");
      previousCardEl.classList.remove("previous--card");
      nextCardEl.classList.remove("next--card");
      leftsecondCardEl.classList.remove("leftsecond--card");
      leftmostCardEl.classList.remove("leftmost--card");
      rightsecondCardEl.classList.remove("rightsecond--card");
      rightmostCardEl.classList.remove("rightmost--card");

      currentBgImageEl.classList.remove("current--image");
      previousBgImageEl.classList.remove("previous--image");
      nextBgImageEl.classList.remove("next--image");
      leftsecondBgImageEl.classList.remove("leftsecond--image");
      leftmostBgImageEl.classList.remove("leftmost--image");
      rightsecondBgImageEl.classList.remove("rightsecond--image");
      rightmostBgImageEl.classList.remove("rightmost--image");

      currentCardEl.style.zIndex = "50";
      currentBgImageEl.style.zIndex = "-2";

      if (direction === "right") {
        console.log("direction right");
        previousCardEl.style.zIndex = "20";
        nextCardEl.style.zIndex = "30";

        nextBgImageEl.style.zIndex = "-1";

        currentCardEl.style.transition = "";
        previousCardEl.style.transition = "";
        nextCardEl.style.transition = "";
        leftsecondCardEl.style.transition = "";
        leftmostCardEl.style.transition = "";
        rightsecondCardEl.style.transition = "";
        rightmostCardEl.style.transition = "";
        currentCardEl.classList.add("previous--card");
        previousCardEl.classList.add("leftsecond--card");
        nextCardEl.classList.add("current--card");
        leftsecondCardEl.classList.add("leftmost--card");
        leftmostCardEl.style.transition = "none";
        leftmostCardEl.classList.add("rightmost--card");

        rightsecondCardEl.classList.add("next--card");
        rightmostCardEl.classList.add("rightsecond--card");

        currentBgImageEl.classList.add("previous--image");
        previousBgImageEl.classList.add("leftsecond--image");
        nextBgImageEl.classList.add("current--image");
        leftsecondBgImageEl.classList.add("leftmost--image");
        leftmostBgImageEl.classList.add("rightmost--image");

        rightsecondBgImageEl.classList.add("next--image");
        rightmostBgImageEl.classList.add("rightsecond--image");
      } else if (direction === "left") {
        console.log("direction left");
        previousCardEl.style.zIndex = "30";
        nextCardEl.style.zIndex = "20";

        previousBgImageEl.style.zIndex = "-1";

        currentCardEl.style.transition = "";
        previousCardEl.style.transition = "";
        nextCardEl.style.transition = "";
        leftsecondCardEl.style.transition = "";
        leftmostCardEl.style.transition = "";
        rightsecondCardEl.style.transition = "";
        rightmostCardEl.style.transition = "";
        currentCardEl.classList.add("next--card");
        previousCardEl.classList.add("current--card");
        nextCardEl.classList.add("rightsecond--card");
        leftsecondCardEl.classList.add("previous--card");
        leftmostCardEl.classList.add("leftsecond--card");
        rightsecondCardEl.classList.add("rightmost--card");
        rightmostCardEl.style.transition = "none";
        rightmostCardEl.classList.add("leftmost--card");

        currentBgImageEl.classList.add("next--image");
        previousBgImageEl.classList.add("current--image");
        nextBgImageEl.classList.add("rightsecond--image");
        leftsecondBgImageEl.classList.add("previous--image");
        leftmostBgImageEl.classList.add("leftsecond--image");
        rightsecondBgImageEl.classList.add("rightmost--image");
        rightmostBgImageEl.classList.add("leftmost--image");
      }
    },

    swapInfosClass(direction, infosArray) {
      const [
        currentInfoEl,
        previousInfoEl,
        nextInfoEl,
        leftsecondInfoEl,
        leftmostInfoEl,
        rightsecondInfoEl,
        rightmostInfoEl,
      ] = infosArray;
      currentInfoEl.classList.remove("current--info");
      previousInfoEl.classList.remove("previous--info");
      nextInfoEl.classList.remove("next--info");
      leftsecondInfoEl.classList.remove("leftsecond--info");
      leftmostInfoEl.classList.remove("leftmost--info");
      rightsecondInfoEl.classList.remove("rightsecond--info");
      rightmostInfoEl.classList.remove("rightmost--info");

      if (direction === "right") {
        currentInfoEl.classList.add("previous--info");
        nextInfoEl.classList.add("current--info");
        previousInfoEl.classList.add("leftsecond--info");
        leftsecondInfoEl.classList.add("leftmost--info");
        leftmostInfoEl.classList.add("rightmost--info");
        rightsecondInfoEl.classList.add("next--info");
        rightmostInfoEl.classList.add("rightsecond--info");
      } else if (direction === "left") {
        currentInfoEl.classList.add("next--info");
        nextInfoEl.classList.add("rightsecond--info");
        previousInfoEl.classList.add("current--info");
        leftsecondInfoEl.classList.add("previous--info");
        leftmostInfoEl.classList.add("leftsecond--info");
        rightsecondInfoEl.classList.add("rightmost--info");
        rightmostInfoEl.classList.add("leftmost--info");
      }
    },

    rerenderEdgeImageCard(direction, infosArray, cardsArray, bgsArray) {
      // console.log(direction, infosArray, cardsArray, bgsArray);
      if (direction === "right") {
        //The add-class operation should have been done in the previous changeInfo and swapCardsClass methods.
        const rightmostInfoEl = infosArray.filter(
          // Because of the asynchronous dom operation of gsap, swapInfosClass is triggered after rerenderEdgeImageCard
          (el) => el.classList.value.indexOf("leftmost--info") > -1
        )[0];
        const rightmostCardEl = cardsArray.filter(
          (el) => el.classList.value.indexOf("rightmost--card") > -1
        )[0];
        const rightmostBgImageEl = bgsArray.filter(
          (el) => el.classList.value.indexOf("rightmost--image") > -1
        )[0];
        let obj = {};
        obj["Name_En"] = rightmostInfoEl.children[0].innerText;
        obj["Year"] = rightmostInfoEl.children[1].innerText;
        obj["Painter_En"] = rightmostInfoEl.children[2].innerText;
        obj["Museum_En"] = rightmostInfoEl.children[3].innerText;
        obj["azure_blob"] =
          rightmostCardEl.lastChild.lastChild.getAttribute("src");
        // Now the right most card is the previous left most card, we need to
        // store its data to the left stack, and replace the right most slot with new data.
        this.LEFT_PUSH_IMAGES_DATA({
          key: this.genre,
          images: [obj],
        });
        if (this.rightHiddenImagesStack[this.genre].length > 1) {
          // Prevent vue getter/setter initialization
          set(
            rightmostInfoEl,
            "children[0].innerText",
            this.rightHiddenImagesStack[this.genre].slice(0, 1)[0]["Name_En"]
          );
          set(
            rightmostInfoEl,
            "children[1].innerText",
            this.rightHiddenImagesStack[this.genre].slice(0, 1)[0]["Year"]
          );
          set(
            rightmostInfoEl,
            "children[2].innerText",
            this.rightHiddenImagesStack[this.genre].slice(0, 1)[0]["Painter_En"]
          );
          set(
            rightmostInfoEl,
            "children[3].innerText",
            this.rightHiddenImagesStack[this.genre].slice(0, 1)[0]["Museum_En"]
          );
          rightmostCardEl.lastChild.lastChild.setAttribute(
            "src",
            this.rightHiddenImagesStack[this.genre].slice(0, 1)[0]["azure_blob"]
          );
          rightmostBgImageEl.lastChild.setAttribute(
            "src",
            this.rightHiddenImagesStack[this.genre].slice(0, 1)[0]["azure_blob"]
          );
          this.rightHiddenImagesStackShift(this.genre);
        } else {
          this.rightPushImagesData(this.genre).then((popData) => {
            const { images: data } = popData;
            set(rightmostInfoEl, "children[0].innerText", data[0]["Name_En"]);
            set(rightmostInfoEl, "children[1].innerText", data[0]["Year"]);
            set(
              rightmostInfoEl,
              "children[2].innerText",
              data[0]["Painter_En"]
            );
            set(rightmostInfoEl, "children[3].innerText", data[0]["Museum_En"]);
            rightmostCardEl.lastChild.lastChild.setAttribute(
              "src",
              data[0]["azure_blob"]
            );
            rightmostBgImageEl.lastChild.setAttribute(
              "src",
              data[0]["azure_blob"]
            );
          });
        }
      } else if (direction === "left") {
        //The add-class operation should have been done in the previous changeInfo and swapCardsClass methods.
        const leftmostInfoEl = infosArray.filter(
          // Because of the asynchronous dom operation of gsap, swapInfosClass is triggered after rerenderEdgeImageCard
          (el) => el.classList.value.indexOf("rightmost--info") > -1
        )[0];
        const leftmostCardEl = cardsArray.filter(
          (el) => el.classList.value.indexOf("leftmost--card") > -1
        )[0];
        const leftmostBgImageEl = bgsArray.filter(
          (el) => el.classList.value.indexOf("leftmost--image") > -1
        )[0];
        let obj = {};
        obj["Name_En"] = leftmostInfoEl.children[0].innerText;
        obj["Year"] = leftmostInfoEl.children[1].innerText;
        obj["Painter_En"] = leftmostInfoEl.children[2].innerText;
        obj["Museum_En"] = leftmostInfoEl.children[3].innerText;
        obj["azure_blob"] =
          leftmostCardEl.lastChild.lastChild.getAttribute("src");
        // Now the left most card is the previous right most card, we need to
        // store its data to the right stack, and replace the left most slot with new data.
        this.RIGHT_UNSHIFT_IMAGES_DATA({
          key: this.genre,
          images: [obj],
        });
        if (this.leftHiddenImagesStack[this.genre].length > 1) {
          // Prevent vue getter/setter initialization
          set(
            leftmostInfoEl,
            "children[0].innerText",
            this.leftHiddenImagesStack[this.genre].slice(-1)[0]["Name_En"]
          );
          set(
            leftmostInfoEl,
            "children[1].innerText",
            this.leftHiddenImagesStack[this.genre].slice(-1)[0]["Year"]
          );
          set(
            leftmostInfoEl,
            "children[2].innerText",
            this.leftHiddenImagesStack[this.genre].slice(-1)[0]["Painter_En"]
          );
          set(
            leftmostInfoEl,
            "children[3].innerText",
            this.leftHiddenImagesStack[this.genre].slice(-1)[0]["Museum_En"]
          );
          leftmostCardEl.lastChild.lastChild.setAttribute(
            "src",
            this.leftHiddenImagesStack[this.genre].slice(-1)[0]["azure_blob"]
          );
          leftmostBgImageEl.lastChild.setAttribute(
            "src",
            this.leftHiddenImagesStack[this.genre].slice(-1)[0]["azure_blob"]
          );
          this.leftHiddenImagesStackPop(this.genre);
        } else {
          this.leftUnshiftImagesData(this.genre).then((popData) => {
            const { images: data } = popData;
            set(leftmostInfoEl, "children[0].innerText", data[0]["Name_En"]);
            set(leftmostInfoEl, "children[1].innerText", data[0]["Year"]);
            set(leftmostInfoEl, "children[2].innerText", data[0]["Painter_En"]);
            set(leftmostInfoEl, "children[3].innerText", data[0]["Museum_En"]);
            leftmostCardEl.lastChild.lastChild.setAttribute(
              "src",
              data[0]["azure_blob"]
            );
            leftmostBgImageEl.lastChild.setAttribute(
              "src",
              data[0]["azure_blob"]
            );
          });
        }
      }
    },

    changeInfo(direction, buttons, infosArray) {
      const [currentInfoEl, previousInfoEl, nextInfoEl] = infosArray;
      // the gsap timeline seems to work asynchronously at dom operation. Hence swapInfosClass is
      // triggered after rerenderEdgeImageCard
      gsap
        .timeline()
        .to([buttons.prev, buttons.next], {
          duration: 0.2,
          opacity: 0.5,
          pointerEvents: "none",
        })
        .to(
          currentInfoEl.querySelectorAll(".text"),
          {
            duration: 0.4,
            stagger: 0.1,
            translateY: "-120px",
            opacity: 0,
          },
          "-="
        )
        .call(() => {
          this.swapInfosClass(direction, infosArray);
        })
        .call(() => this.initCardEvents())
        .fromTo(
          direction === "right"
            ? nextInfoEl.querySelectorAll(".text")
            : previousInfoEl.querySelectorAll(".text"),
          {
            opacity: 0,
            translateY: "40px",
          },
          {
            duration: 0.4,
            stagger: 0.1,
            translateY: "0px",
            opacity: 1,
          }
        )
        .to([buttons.prev, buttons.next], {
          duration: 0.2,
          opacity: 1,
          pointerEvents: "all",
        });
    },

    swapCards: throttle(
      function (direction) {
        this.likeit = false; // Update likeit to false when it swaps
        const { cardsContainerEl, appBgContainerEl, cardInfosContainerEl } =
          this;
        this.$nextTick(() => {
          const buttons = {
            prev: document.querySelector(".btn--left"),
            next: document.querySelector(".btn--right"),
          };
          const currentCardEl =
            cardsContainerEl.querySelector(".current--card");
          const previousCardEl =
            cardsContainerEl.querySelector(".previous--card");
          const nextCardEl = cardsContainerEl.querySelector(".next--card");
          const leftsecondCardEl =
            cardsContainerEl.querySelector(".leftsecond--card");
          const leftmostCardEl =
            cardsContainerEl.querySelector(".leftmost--card");
          const rightsecondCardEl =
            cardsContainerEl.querySelector(".rightsecond--card");
          const rightmostCardEl =
            cardsContainerEl.querySelector(".rightmost--card");

          const currentBgImageEl =
            appBgContainerEl.querySelector(".current--image");
          const previousBgImageEl =
            appBgContainerEl.querySelector(".previous--image");
          const nextBgImageEl = appBgContainerEl.querySelector(".next--image");
          const leftsecondBgImageEl =
            appBgContainerEl.querySelector(".leftsecond--image");
          const leftmostBgImageEl =
            appBgContainerEl.querySelector(".leftmost--image");
          const rightsecondBgImageEl = appBgContainerEl.querySelector(
            ".rightsecond--image"
          );
          const rightmostBgImageEl =
            appBgContainerEl.querySelector(".rightmost--image");

          const currentInfoEl =
            cardInfosContainerEl.querySelector(".current--info");
          const previousInfoEl =
            cardInfosContainerEl.querySelector(".previous--info");
          const nextInfoEl = cardInfosContainerEl.querySelector(".next--info");
          const leftsecondInfoEl =
            cardInfosContainerEl.querySelector(".leftsecond--info");
          const leftmostInfoEl =
            cardInfosContainerEl.querySelector(".leftmost--info");
          const rightsecondInfoEl =
            cardInfosContainerEl.querySelector(".rightsecond--info");
          const rightmostInfoEl =
            cardInfosContainerEl.querySelector(".rightmost--info");

          const cardsArray = [
            currentCardEl,
            previousCardEl,
            nextCardEl,
            leftsecondCardEl,
            leftmostCardEl,
            rightsecondCardEl,
            rightmostCardEl,
          ];
          const bgsArray = [
            currentBgImageEl,
            previousBgImageEl,
            nextBgImageEl,
            leftsecondBgImageEl,
            leftmostBgImageEl,
            rightsecondBgImageEl,
            rightmostBgImageEl,
          ];
          const infosArray = [
            currentInfoEl,
            previousInfoEl,
            nextInfoEl,
            leftsecondInfoEl,
            leftmostInfoEl,
            rightsecondInfoEl,
            rightmostInfoEl,
          ];
          this.changeInfo(direction, buttons, infosArray);
          this.swapCardsClass(direction, cardsArray, bgsArray);
          this.rerenderEdgeImageCard(
            direction,
            infosArray,
            cardsArray,
            bgsArray
          );

          this.removeCardEvents(currentCardEl);
        });
      },
      3000,
      {
        leading: true,
        trailing: false,
      }
    ),

    initializeAnimatedImages() {
      const buttons = {
        prev: document.querySelector(".btn--left"),
        next: document.querySelector(".btn--right"),
      };
      const cardsContainerEl = document.querySelector(".cards__wrapper");

      const cardInfosContainerEl = document.querySelector(".info__wrapper");
      this.initCardEvents();

      const init = () => {
        let tl = gsap.timeline();
        tl.to(cardsContainerEl.children, {
          delay: 0.15,
          duration: 0.5,
          stagger: {
            ease: "power4.inOut",
            from: "right",
            amount: 0.1,
          },
          "--card-translateY-offset": "0",
        })
          .to(
            cardInfosContainerEl
              .querySelector(".current--info")
              .querySelectorAll(".text"),
            {
              delay: 0.5,
              duration: 0.4,
              stagger: 0.1,
              opacity: 1,
              translateY: 0,
            }
          )
          .to(
            [buttons.prev, buttons.next],
            {
              duration: 0.4,
              opacity: 1,
              pointerEvents: "all",
            },
            "-=0.4"
          );
      };

      const waitForImages = () => {
        const images = [...document.querySelectorAll("img")];
        const totalImages = images.length;
        let loadedImages = 0;
        const loaderEl = document.querySelector(".loader span");

        gsap.set(cardsContainerEl.children, {
          "--card-translateY-offset": "100vh",
        });
        gsap.set(
          cardInfosContainerEl
            .querySelector(".current--info")
            .querySelectorAll(".text"),
          {
            translateY: "40px",
            opacity: 0,
          }
        );
        gsap.set([buttons.prev, buttons.next], {
          pointerEvents: "none",
          opacity: "0",
        });

        images.forEach((image) => {
          imagesLoaded(image, (instance) => {
            if (instance.isComplete) {
              loadedImages++;
              let loadProgress = loadedImages / totalImages;

              gsap.to(loaderEl, {
                duration: 1,
                scaleX: loadProgress,
                backgroundColor: `hsl(${loadProgress * 120}, 100%, 50%)`,
              });

              if (totalImages == loadedImages) {
                gsap
                  .timeline()
                  .to(".loading__wrapper", {
                    // delay 1 second to resolve cached image scenario and display
                    // the loading bar as full
                    delay: 1,
                    duration: 0.8,
                    opacity: 0,
                    pointerEvents: "none",
                  })
                  .call(() => init());
              }
            }
          });
        });
      };

      waitForImages();
    },

    executeWholeBoostArt() {
      // reset loader bar to 0%
      const loaderEl = document.querySelector(".loader span");
      gsap.set(loaderEl, {
        duration: 1,
        scaleX: 0,
        backgroundColor: `hsl(${0 * 120}, 100%, 50%)`,
      });
      // keep leftmenu overlay the loading layout
      const leftMenu = document.querySelector(".navigation-view-container");
      gsap.set(leftMenu, {
        "z-index": "250",
      });
      // show the loading layout
      const loadingWrapper = document.querySelector(".loading__wrapper");
      gsap.set(loadingWrapper, {
        "pointer-events": "unset",
        opacity: "unset",
      });
      // move the previous image boxes below the screen
      const cardsContainerEl = document.querySelector(".cards__wrapper");
      gsap.set(cardsContainerEl.children, {
        "--card-translateY-offset": "100vh",
      });

      gsap.to(loaderEl, {
        duration: 1,
        scaleX: 0,
        backgroundColor: `hsl(${0 * 120}, 100%, 50%)`,
      });

      this.initializeImagesData(this.genre).then(() => {
        this.mobile = window.innerWidth <= 450;
        this.ipad = Boolean(
          !!navigator.userAgent.match(/(iPad).*OS\s([\d_]+)/) || // browser devtools ipad
            (/Macintosh/i.test(navigator.userAgent) && // real ipad devices
              navigator.maxTouchPoints &&
              navigator.maxTouchPoints > 1)
        );
        if (this.mobile || this.ipad) {
          // enable touch to left and touch to right
          this.cardsContainerEl.children.forEach((card) => {
            card.setAttribute("touchstartListener", "true");
            card.addEventListener("touchstart", (e) => {
              this.touchStart(e);
            });
          });
        }
        if (this.firstPaintedDetector) {
          this.initializeAnimatedImages();
        } else {
          setTimeout(() => {
            this.initializeAnimatedImages();
          }, 1000);
        }
      });

      // Trick to prevent image sharp rendering
      this.$nextTick(() => {
        this.leftUnshiftImagesData(this.genre);
        this.rightPushImagesData(this.genre);
      });

      !this.documentEvtFunc && this.registerKeyboardEvent();
    },
  },

  mounted() {
    // Initial Carousel Render
    this.executeWholeBoostArt();
  },

  beforeDestroy() {
    this.terminateKeyboardEvent();
  },
};
</script>

<style lang="less">
@import "./style.less";
</style>
